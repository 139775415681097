import {mapActions, mapGetters, mapMutations} from "vuex";

import {dynamicHead} from '@/mixins/dynamic-head.js'
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
import Vue from 'vue';
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-tel-input/dist/css/sprite.css'; // Flags styles
import 'vue-tel-input/dist/css/component.css'; // Component styles
import { StripeElementCard, StripeCheckout, StripeElementPayment } from '@vue-stripe/vue-stripe';
Vue.use(VueTelInput);

import orderItem from "@/modules/order/components/order-item/index.vue";
import {SET_CONTACTS} from "@/store/mutation-types";

export default {
  name: "order",
  mixins: [dynamicHead, validationMixin],
  components:{
    orderItem,
    StripeElementCard,
    StripeCheckout,
    StripeElementPayment,
  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
        {name: 'cart', title: this.$t('cart'), slug: ''},
        {name: 'order', title: this.$t('order'), slug: ''}
      ],
      key: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      stripe: false,
      stripeKey: null,
      payload: {
        last_name: '',
        name: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        address: '',
        comment: '',
        payment_method_id: null,
        delivery_method_id: null,
        token: null,
      },
      promocode: '',
      delivery: {},
      validationErrors: {},
      vueTel: {
        phone: '',
        props: {
          maxLen: 11,
          validCharactersOnly: true,
          mode: "international",
          dynamicPlaceholder: 'true',
          autoDefaultCountry: false,
          disabled: false,
          required: true,
          enabledCountryCode: false,
          enabledFlags: true,
          onlyCountries: [],
          ignoredCountries: [],
          autocomplete: "off",
          name: "telephone",
          inputClasses: "",
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: true,
          wrapperClasses: "label"
        }
      },
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: null
      },
      confirmParams: {
        return_url: null
      },
    }
  },
  watch: {
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        this.crumbs[1].title = newVal.cart
        this.crumbs[2].title = newVal.order
      }
    },
    delivery: {
      immediate: true,
      handler(newVal) {
        if(newVal){
          this.payload.delivery_method_id = newVal?.id
        }
      }
    },
  },
  validations: {
    payload: {
      last_name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2)
      },
      name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2),
      },
      phone: {
        required,
        maxLength: maxLength(18),
        minLength: minLength(7),
      },
      country: {
        required,
        maxLength: maxLength(30),
        minLength: minLength(2),
      },
      city: {
        required,
        maxLength: maxLength(200),
        minLength: minLength(2),
      },
      address: {
        required,
        maxLength: maxLength(200),
        minLength: minLength(2),
      },
      email: {
        email,
        maxLength: maxLength(100),
        required,
      },
      comment: {
        maxLength: maxLength(250),
      },
    },
  },
  created() {
    this.fetchMeta('home').then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
    this.getDeliveryMethods().then(()=>{
      if(this.deliveryList.length){
        this.delivery = this.deliveryList[0]
      }
    })
    this.getPaymentMethods().then(()=>{
      if(this.paymentList.length){
        this.payload.payment_method_id = this.paymentList[0].id
        this.paymentList[0].slug === 'stripe' ? this.stripe = true : this.stripe === false
      }
    })
    if(this.isAuthenticated){
      this.getUserData().then(()=>{
          this.setUserData()
      }).catch(error => {
        if (error.status === 418) {
          this.$toasted.error(error.data.message);
          const errors = error.data.errors;
          for (const i in errors) {
            errors[i].forEach(e => {
              this.$toasted.error(e);
            })
          }
          this.$router.push({name: 'home'}).catch(()=>{
            console.log()
          })
        }
      })
    }
  },
  computed:{
    ...mapGetters([
      'currentLanguage',
    ]),
    ...mapGetters({
      basket: 'basket/basket',
      meta: 'meta/meta',
      isAuthenticated: 'auth/isAuthenticated',
      user: 'profile/user',
      deliveryList: 'order/delivery',
      paymentList: 'order/payment',
      loadingOrder: 'order/loadingOrder',
      loadingPromocode: 'order/loadingPromocode',
    }),
    nameError() {
      const error = []
      if (!this.$v.payload.name.$dirty) {
        return error
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    phoneError() {
      const error = []
      if (!this.$v.payload.phone.$dirty) {
        return error
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 15))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 7))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 100))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    countryError() {
      const error = []
      if (!this.$v.payload.country.$dirty) {
        return error
      }
      if (!this.$v.payload.country.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.country.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 30))
      }
      if (!this.$v.payload.country.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.country) {
        this.validationErrors.country.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    cityError() {
      const error = []
      if (!this.$v.payload.city.$dirty) {
        return error
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.city.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 200))
      }
      if (!this.$v.payload.city.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    addressError() {
      const error = []
      if (!this.$v.payload.address.$dirty) {
        return error
      }
      if (!this.$v.payload.address.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.address.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 200))
      }
      if (!this.$v.payload.address.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.address) {
        this.validationErrors.address.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    commentError() {
      const error = []
      if (!this.$v.payload.comment.$dirty) {
        return error
      }
      if (!this.$v.payload.comment.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 250))
      }
      if (this.validationErrors.comment) {
        this.validationErrors.comment.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
  },

  mounted () {

  },

  methods:{
    ...mapActions({
      fetchMeta: 'meta/GET_META',
      getUserData: 'profile/FETCH_USER_DATA',
      getDeliveryMethods: 'order/GET_DELIVERY',
      getPaymentMethods: 'order/GET_PAYMENT',
      createOrder: 'order/CREATE_ORDER',
      postPromocode: 'order/SUBMIT_PROMOCODE',
      getBasket: 'basket/GET_BASKET'
    }),
    ...mapMutations({
      updateOrderLoading: 'order/LOADING_ORDER',
    }),
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    },
    setUserData(){
      this.payload.last_name = this.user.lastName
      this.payload.name = this.user.name
      this.payload.email = this.user.email
      this.payload.phone = this.user.phone
      this.payload.country = this.user.country
      this.payload.city = this.user.city
    },
    changePayment(payment){
      payment.slug ==='stripe' ? this.stripe = true : this.stripe = false
    },
    tokenCreated (token) {
      this.submitForm(token)
    },
    stripePay () {
      this.$refs.paymentRef.submit();
    },
    stripeLoading (state) {
      this.updateOrderLoading(state);
    },
    openRedirect(url){
      window.innerWidth < 1024 ? window.open(url, '_top') : window.open(url, '_blank')
    },
    submitPromocode(){
      this.postPromocode({promocode: this.promocode}).then(()=>{
        this.$toasted.success(this.$t('success'))
        this.getBasket()
      }).catch(()=>{
        this.$toasted.error(this.$t('promoDontExist'))
        this.promocode = ''
      })
    },
    purchaseEvent() {
      let obj = {
        event: "purchase",
        ecommerce: {
          affiliation: window.location.origin,
          value: (this.isAuthenticated && this.user) ? (this.basket.price - ((this.basket.price/100) * this.user.bonus)) : this.basket.price,
          currency: localStorage.getItem('currency'),
          items: this.basket.products.data.map(el=>{
            return {
              item_id: el.id,
              item_name: el.title,
              price: el.withAttributePrice,
              quantity: el.count,
            }
          }),
        }
      }
      localStorage.setItem('purchaseData', JSON.stringify(obj))
    },
    submitForm(token) {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        token ? this.payload.token = token.id : this.payload.token = null
        this.createOrder(this.payload).then((res) => {
          this.purchaseEvent()
          if(res.clientSecret && res.clientSecret.length) { //stripe payment

            // update stripe payment form
            this.elementsOptions.clientSecret = res.clientSecret;
            this.confirmParams.return_url = res.redirectUrl;

          }
          else if(res.url && res.url.length > 0){ // paypal redirect
              setTimeout(()=>{
                this.openRedirect(res.url)
              })
          } else {
            this.$toasted.success(this.$t('success'))
            localStorage.removeItem('cart_id')
            this.$router.push({name: 'success'}).catch(()=>{
              console.log()
            })
          }
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for(const i in errors){
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}